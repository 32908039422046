import Layout from "@components/layouts/Layout";
import axios from "axios";
import React, { useState, useEffect } from "react";
import Seo from "@components/common/Seo";
import { Link } from "gatsby";
import { toast } from "react-toastify";
import { Zoom } from "react-slideshow-image";
import dayjs from "dayjs";
import ReactMarkdown from "react-markdown";
import { v4 as uuid } from "uuid";
import "react-slideshow-image/dist/styles.css";
import { FaFacebook, FaTwitter, FaLinkedin, FaTelegram } from "react-icons/fa";
import { RenderPostAlt } from "./components/renderPost";
import { DiscussionEmbed } from "disqus-react";
import RenderLoading from "./components/loading";
import { FacebookEmbed, InstagramEmbed, XEmbed } from "react-social-media-embed";

// Importamos el idioma español para dayjs y lo activamos
require("dayjs/locale/es");
dayjs.locale("es");

// Activamos plugin de dayjs para mostrar la fecha en formato relativo
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

// Fix para que funciona ReactJson
// const ReactJson = typeof window !== `undefined` ? require("react-json-view").default : null;

const Component = ({ pageContext: { slug, preview, seo } }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Capturamos la información adicional del post
        async function fetchData() {
            setLoading(true);

            const toastId = toast.loading("Cargando datos...", {
                position: "bottom-center",
            });

            const rawData = await getPost(slug, preview);
            setData(rawData);
            setLoading(false);
            toast.dismiss(toastId);
        }

        fetchData();
    }, [slug, preview]);

    return (
        <>
            <RenderSeo post={data?.post} seo={seo} />
            <Layout>
                {loading && <RenderLoading />}
                {data && <RenderPost data={data} />}
            </Layout>
        </>
    );
};

const RenderSeo = ({ post, seo }) => {
    const author = seo?.author || post?.attributes?.author?.data?.attributes?.name;
    const title = seo.title || post?.attributes?.title || "";
    const description = seo.description || post?.attributes?.description || "";
    const image =
        seo.image ||
        post?.attributes?.seo?.metaImage?.data?.attributes?.formats?.thumbnail?.url ||
        post?.attributes?.cover?.data?.attributes?.url;

    return <Seo title={title} description={description} author={author} image={image} />;
};

const RenderPost = ({ data }) => {
    if (!data) return null;

    const post = data?.post;
    const related = data?.related;

    const cover = post.attributes.cover;
    const author = post.attributes.author;
    const blocks = post.attributes.blocks;

    return (
        <>
            <article className={styles.container}>
                <RenderHeader
                    title={post.attributes.title}
                    description={post.attributes.description}
                />

                <Separator />

                <RenderCover cover={cover} title={post.attributes.title} />

                <RenderDateAndCategories
                    date={post.attributes.publishedAt}
                    categories={post.attributes.categories}
                />

                <Separator margin="my-5" />

                <RenderAuthor author={author} />

                <RenderBlocks blocks={blocks} />

                <Separator margin="my-5" />

                <RenderSharePost post={post} />

                <RenderRelated data={related} />

                <RenderComments data={post} />
            </article>
        </>
    );
};

const getPost = async (slug, preview = false) => {
    try {
        const { data } = await axios.post(
            `/.netlify/functions/post`,
            {
                slug,
                preview,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Strapi-Response-Format": "v4",
                },
            },
        );

        return data;
    } catch (err) {
        console.error(err);
        return null;
    }
};

const RenderHeader = ({ title, description }) => {
    return (
        <>
            <h1 className={styles.title}>{title}</h1>
            <h2 className={styles.subtitle}>{description}</h2>
        </>
    );
};

const RenderCover = ({ cover, title }) => {
    if (!cover || !cover.data) return null;

    return <img src={cover.data.attributes.url} alt={title} className={styles.cover} />;
};

const RenderDateAndCategories = ({ date, categories }) => {
    // Calculamos tiempos
    const currentDate = dayjs();

    // Calcula la diferencia en días entre la fecha actual y la fecha de publicación
    const daysDifference = currentDate.diff(dayjs(date), "hour") / 24.0;

    return (
        <div className={styles.categories.container}>
            <p className="space-x-2">
                {categories?.data?.map((category, index) => {
                    const slug = category.attributes.slug;
                    const name = category.attributes.name;
                    const isLast = index === categories.data.length - 1;

                    return (
                        <React.Fragment key={uuid()}>
                            <Link
                                to={`/blog/categoria/${slug}`}
                                title={name}
                                className={styles.categoryLink}
                            >
                                <span className={styles.category}>{name}</span>
                            </Link>
                            {!isLast && <span className="text-gray-500">|</span>}
                        </React.Fragment>
                    );
                })}
            </p>
            {/** Separador */}
            <span>-</span>

            {date ? (
                <time dateTime={dayjs(date).format("YYYY-MM-DD HH:mm:ss")} className={styles.date}>
                    {daysDifference > 1
                        ? dayjs(date).format("DD MMMM YYYY - HH:mm")
                        : dayjs(date).fromNow(false)}
                </time>
            ) : (
                <span className="text-red-700"> Artículo no publicado</span>
            )}
        </div>
    );
};

const RenderAuthor = ({ author }) => {
    const url =
        author?.data?.attributes?.avatar?.data?.attributes?.formats?.thumbnail?.url ||
        author.data.attributes.avatar.data.attributes.url;

    return (
        <div className={styles.author.container}>
            <img src={url} alt={author.data.attributes.name} className={styles.author.avatar} />

            <div>
                <h1 className={styles.author.name}>{author.data.attributes.name}</h1>

                <h2 className={styles.author.position}>{author.data.attributes.position}</h2>
            </div>
        </div>
    );
};

const Separator = ({ margin }) => {
    return <hr className={`mx-auto max-w-4xl ${margin ? margin : "my-10"}`} />;
};

const RenderBlocks = ({ blocks }) => {
    if (!blocks || blocks.length === 0) return null;

    return (
        <div className={styles.blocks.container}>
            {blocks.map(b => {
                switch (b.__component) {
                    case "shared.rich-text":
                        return <RenderBlockRichText content={b.body} key={b.id} />;

                    case "shared.image":
                        return (
                            <RenderBlockImage
                                key={uuid()}
                                title={b.title}
                                image={b.file.data.attributes.url}
                                caption={b.file.data.attributes.caption}
                                alternativeText={b.file.data.attributes.alternativeText}
                            />
                        );

                    case "shared.video-embed":
                        return <RenderBlockVideo video={b.url} key={uuid()} />;

                    case "shared.quote":
                        return <RenderQuote quote={b} key={uuid()} />;

                    case "shared.slider":
                        return <RenderSlider key={uuid()} slider={b.files.data} />;

                    case "shared.audio-embed":
                        return <RenderBlockAudio audio={b.url} key={uuid()} />;

                    case "shared.related-post":
                        return <RenderRelatedPost key={uuid()} post={b.post} />;

                    case "shared.social-embed":
                        return <RenderSocial key={uuid()} data={b} />;

                    default:
                        return null;
                }
            })}
        </div>
    );
};

const RenderSharePost = ({ post }) => {
    const slug = post.attributes.slug;
    const title = post.attributes.title;
    const url = `https://www.iopos.es/blog/${slug}`;

    // Encodemamos la URL para que sea válida en las redes sociales
    const encodedUrl = encodeURIComponent(url);
    const encodedTitle = encodeURIComponent(title);

    return (
        <div className="mx-auto max-w-4xl my-5">
            <h1 className="font-bold text-gray-500 my-3">Compartir</h1>

            <div className={styles.shared.container}>
                <Link
                    to={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}
                    className={styles.shared.link}
                    title="Comparte en Facebook"
                >
                    <FaFacebook className={styles.shared.icon} />
                </Link>

                <Link
                    to={`https://twitter.com/intent/tweet?text=${encodedUrl}`}
                    className={styles.shared.link}
                    title="Comparte en Twitter"
                >
                    <FaTwitter className={styles.shared.icon} />
                </Link>

                <Link
                    to={`https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}`}
                    className={styles.shared.link}
                    title="Comparte en LinkedIn"
                >
                    <FaLinkedin className={styles.shared.icon} />
                </Link>

                <Link
                    to={`https://t.me/share/url?url=${encodedUrl}&text=${encodedTitle}`}
                    className={styles.shared.link}
                    title="Comparte en Telegram"
                >
                    <FaTelegram className={styles.shared.icon} />
                </Link>
            </div>
        </div>
    );
};

const CustomLink = ({ href, children }) => {
    return (
        <a href={href} target="_blank" rel="noreferrer" className={styles.blocks.richText.link}>
            {children}
        </a>
    );
};

const renderers = {
    link: CustomLink,
    strong: ({ children }) => <span className={styles.blocks.richText.strong}>{children}</span>,
    h1: ({ children }) => <h1 className="text-2xl font-bold">{children}</h1>,
    h2: ({ children }) => <h2 className="text-xl font-bold">{children}</h2>,
    h3: ({ children }) => <h3 className="text-lg font-bold">{children}</h3>,
    image: ({ src, alt }) => <img src={src} alt={alt} className="rounded w-full" />,
};

const RenderBlockRichText = ({ content }) => {
    return (
        <div className="leading-7 text-justify text-gray-700 text-sm lg:text-base richt-text space-y-5">
            <ReactMarkdown renderers={renderers}>{content}</ReactMarkdown>
        </div>
    );
};

const RenderBlockImage = ({ image, title, caption, alternativeText }) => {
    const text = title || caption || alternativeText;

    return (
        <figure className={styles.blocks.image.figure}>
            <img
                src={image}
                alt={caption || alternativeText}
                className={styles.blocks.image.image || ""}
            />
            {text && <figcaption className={styles.blocks.image.caption}>{text}</figcaption>}
        </figure>
    );
};

const RenderBlockAudio = ({ audio }) => {
    const embedUrl = getEmbedAudioUrl(audio);

    return (
        <div className="audio-embed rounded">
            <iframe
                title="Audio"
                frameborder="0"
                className="w-full mx-auto h-56"
                src={embedUrl || ""}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                loading="lazy"
            />
        </div>
    );
};

const RenderBlockVideo = ({ video }) => {
    const embedUrl = getEmbedVideoUrl(video);

    if (!embedUrl) return <div>Url inválida.</div>;

    return (
        <div className="video-embed relative pb-56.25 h-72 lg:h-110 overflow-hidden my-8 rounded">
            <iframe
                title="video"
                src={embedUrl || ""}
                width={"100%"}
                height={"100%"}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="absolute top-0 left-0 w-full h-full"
            />
        </div>
    );
};

const RenderQuote = ({ quote }) => {
    const { title, body, author } = quote;

    return (
        <div className="flex flex-col items-center mx-12 lg:mx-0 py-44 text-gray-700 border-t-2 border-b-2 py-3">
            {title && <h2 className="my-4 text-ccyan-dark font-medium">{title}</h2>}
            <div className="relative text-center">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    fill="currentColor"
                    className="absolute top-0 -left-4 w-4 h-4 dark:text-gray-700"
                >
                    <path d="M232,246.857V16H16V416H54.4ZM48,48H200V233.143L48,377.905Z"></path>
                    <path d="M280,416h38.4L496,246.857V16H280ZM312,48H464V233.143L312,377.905Z"></path>
                </svg>
                <p className="px-6 py-1 lg:text-lg italic">{body}</p>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    fill="currentColor"
                    className="absolute bottom-0 -right-4 w-4 h-4 dark:text-gray-700"
                >
                    <path d="M280,185.143V416H496V16H457.6ZM464,384H312V198.857L464,54.1Z"></path>
                    <path d="M232,16H193.6L16,185.143V416H232ZM200,384H48V198.857L200,54.1Z"></path>
                </svg>
            </div>
            <span className="w-12 h-1 my-2 rounded-lg"></span>
            {author && <p className="text-cpurple">{author}</p>}
        </div>
    );
};

const RenderSlider = ({ slider }) => {
    return (
        <div className="rounded overflow-hidden">
            <Zoom infinite={true} canSwipe={true} scale={1.4} duration={5000}>
                {slider.map(slide => (
                    <div key={uuid()} className="rounded border">
                        <img
                            alt={slide.attributes.alternativeText || slide.attributes.caption}
                            height={600}
                            width={600}
                            src={slide.attributes.url}
                            className="object-cover w-full rounded"
                        />
                    </div>
                ))}
            </Zoom>
        </div>
    );
};

const RenderRelatedPost = ({ post }) => {
    if (!post) return null;

    const title = post?.data?.attributes?.title;
    const slug = post?.data?.attributes?.slug;
    const publishedAt = post?.data?.attributes?.publishedAt;
    const cover =
        post?.data?.attributes?.cover?.data?.attributes?.formats?.thumbnail?.url ||
        post?.data?.attributes?.cover?.data?.attributes?.url;

    return (
        <div className="my-10">
            <Link to={`/blog/${slug}`} className={styles.blocks.relatedPost.link}>
                <div className="border-t border-b py-5 flex flex-col gap-5 md:flex-row px-10 md:items-center">
                    <img src={cover} alt={title} className={styles.blocks.relatedPost.image} />

                    <div>
                        <h2 className={styles.blocks.relatedPost.subtitle}>En iOpos</h2>
                        <h1 className={styles.blocks.relatedPost.title}>{title}</h1>
                        <h3>
                            <time
                                dateTime={dayjs(publishedAt).toISOString()}
                                className={styles.blocks.relatedPost.date}
                            >
                                {dayjs(publishedAt).format("DD MMMM YYYY HH:mm")}
                            </time>
                        </h3>
                    </div>
                </div>
            </Link>
        </div>
    );
};

const getEmbedVideoUrl = videoUrl => {
    const youtubeRegex = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|watch\?v%3D)([\w-]{11}).*/;
    const youtubeMatch = videoUrl.match(youtubeRegex);

    if (youtubeMatch && youtubeMatch[2].length === 11) {
        return `https://www.youtube.com/embed/${youtubeMatch[2]}`;
    }

    // Podemos añadir soporte para otro tipo de vídeos

    return null;
};

const getEmbedSocial = url => {
    const facebookRegex = /^.*(facebook.com\/)([a-zA-Z0-9]{1,50}).*/;
    const facebookMatch = url.match(facebookRegex);

    if (facebookMatch && facebookMatch[2].length <= 50) {
        return "facebook";
    }

    const instagramRegex = /^.*(instagram.com\/)([a-zA-Z0-9]{1,50}).*/;
    const instagramMatch = url.match(instagramRegex);

    if (instagramMatch && instagramMatch[2].length <= 50) {
        return "instagram";
    }

    const twitterRegex = /^.*(twitter\.com|x\.com\/)([a-zA-Z0-9]{1,50}).*/;
    const twitterMatch = url.match(twitterRegex);

    if (twitterMatch && twitterMatch[2].length <= 50) {
        return "twitter";
    }

    return null;
};

const getEmbedAudioUrl = audioUrl => {
    const ivooxRegex = /^.*(ivoox.com\/player_ej_)([0-9]{9}).*/;
    const ivooxMatch = audioUrl.match(ivooxRegex);

    if (ivooxMatch && ivooxMatch[2].length === 9) {
        return `https://www.ivoox.com/player_ej_${ivooxMatch[2]}_6_1.html?c1=fcb404`;
    }

    // Podemos añadir soporte para otro tipo de audios

    return null;
};

const RenderRelated = ({ data }) => {
    if (!data || data.length === 0) return null;

    return (
        <div className={styles.related.container}>
            <h1 className={styles.related.title}>Relacionados</h1>

            <div className="grid grid-cols-1 gap-10 mt-10">
                {data.map(post => (
                    <RenderPostAlt
                        key={uuid()}
                        featured={true}
                        data={post}
                        styleClass={"preview"}
                    />
                ))}
            </div>
        </div>
    );
};

const RenderComments = ({ data: post }) => {
    if (!post?.attributes?.comments) return null;

    const slug = post?.attributes?.slug;
    const identifier = post?.id;
    const title = post?.attributes?.title;
    const disqusShortname = "iopos-academia";

    const disqusConfig = {
        url: `https://iopos.es/blog/${slug}`,
        identifier,
        title,
    };

    return (
        <div className={styles.comments.container}>
            <h1 className={styles.comments.title}>Comentarios</h1>
            <div className="mt-10">
                <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
            </div>
        </div>
    );
};

const RenderSocial = ({ data }) => {
    const social = getEmbedSocial(data.url);

    if (social === "instagram") {
        return <InstagramEmbed url={data.url} captioned />;
    } else if (social === "facebook") {
        return <FacebookEmbed url={data.url} />;
    } else if (social === "twitter") {
        return <XEmbed url={data.url} />;
    } else {
        return <div></div>;
    }
};

const styles = {
    container: "mx-auto container py-10 px-10 lg:px-0 font-montserrat",
    title: "font-bold text-gray-600 text-2xl lg:text-3xl mx-auto max-w-4xl ",
    subtitle: "font-medium text-gray-600 text-lg lg:text-xl mx-auto max-w-4xl mt-4",
    cover: "w-full rounded object-cover",
    categories: {
        container: "my-5 mx-auto max-w-4xl text-gray-600 font-medium flex space-x-2",
        categoryLink: "font-semibold text-gray-600 hover:text-ccyan duration-300",
        category: "",
    },
    author: {
        container: "flex gap-5 flex-row mx-auto max-w-4xl",
        name: "font-bold text-gray-700 text-base",
        avatar: "w-12 h-12 rounded-full object-cover flex-shrink-0",
        position: "truncate-2-lines text-xs text-gray-600 font-montserrat",
    },
    blocks: {
        container: "mx-auto max-w-4xl my-5 space-y-8",
        image: {
            figure: "space-y-1",
            image: "w-full rounded object-cover",
            caption: "text-center text-gray-600 text-xs italic",
        },
        richText: {
            link: "text-ccyan border-b border-ccyan border-opacity-50 duration-300 hover:border-transparent font-medium",
            strong: "font-semibold text-cpurple",
        },
        relatedPost: {
            title: "font-semibold text-sm text-gray-700 truncate-2-lines",
            subtitle: "text-gray-600 uppercase text-xs",
            image: "object-cover h-24 flex-shrink-0 rounded",
            link: "opacity-75 duration-300 hover:opacity-100",
            date: "text-xs font-semibold text-yellow-700 uppercase",
        },
        social: {},
    },
    shared: {
        container: "flex items-center space-x-5 text-gray-600",
        link: "hover:text-ccyan duration-300",
        icon: "w-6 h-6",
    },
    related: {
        container: "my-20",
        title: "font-bold text-gray-600 text-2xl lg:text-3xl border-b-2",
    },
    comments: {
        container: "my-20",
        title: "font-bold text-gray-600 text-2xl lg:text-3xl border-b-2",
    },
};

export default Component;
